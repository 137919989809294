import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../context/AuthContext'; // Adjusted path
import useApi from '../utility/apiCall'; // Adjusted path
import API_URL from '../config/config'; // Ensure this points to the correct API URL
import { useNavigate } from 'react-router-dom';

export default function LocationMap({ className = "col-12" }) {
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state } = useAuth();
  const token = state.token || '';
  const mapRef = useRef(null); // Reference for the map container
  const [isLoading, setIsLoading] = useState(false);
  const [userDeviceData, setUserDeviceData] = useState(null);
  const [error, setError] = useState(null);

  const getUserDeviceData = async () => {
    setIsLoading(true);
    try {
      const response = await apiCall(API_URL + 'get-user-device-data', {
        user_id: state.userData.id,
        token: token,
      });

      console.log('API Response:', response);

      if (response.data.success === 0 && response.data.data) {
        setUserDeviceData(response.data.data);
      } else {
        setError('No device data found for this user.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Please login again to check your location');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserDeviceData();
  }, []);

  useEffect(() => {
    if (userDeviceData && mapRef.current) {
      // Initialize the Google Map
      const map = new window.google.maps.Map(mapRef.current, {
        center: {
          lat: parseFloat(userDeviceData.latitude),
          lng: parseFloat(userDeviceData.longitude),
        },
        zoom: 14,
      });

      // Add a marker to the map
      new window.google.maps.Marker({
        position: {
          lat: parseFloat(userDeviceData.latitude),
          lng: parseFloat(userDeviceData.longitude),
        },
        map: map,
        title: "User Location",
        icon: {
          url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png", // Red marker icon
        },
      });
    }
  }, [userDeviceData]);

  return (
    <div className={className}>
      <div className="common-box">
        <div className="common-box-heading">Location</div>
        <div className="location-map-bx" style={{ height: "250px" }}>
          {isLoading ? (
            <p>Loading map...</p>
          ) : userDeviceData ? (
            <div
              ref={mapRef}
              style={{ width: "100%", height: "100%", borderRadius: "10px" }}
            ></div>
          ) : (
            <p>{error || 'No device data found or failed to load map.'}</p>
          )}
        </div>
      </div>
    </div>
  );
}
