import React, { useState, useRef, useEffect } from 'react';

import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
// React Bootstrap
import { Modal } from 'react-bootstrap';

import Breadcrumb from '../../components/Breadcrumb'
import ChallengeCard from '../../components/ChallengeCard';
import ChallengeRequestCard from '../../components/ChallengeRequestCard';

// Icons & Images Files
import Icons from '../../components/icons';
import master from "../../img/icons/account/master.png";
import student from "../../img/icons/account/student.png";
import practitioner from "../../img/icons/account/practitioner.png";
import CloseIcon from '../../components/CloseIcon';
import mt5exe from '../../img/mt5.exe';
import Loaders from '../../components/Loader';
import FlashMessage from '../../components/FlashMessage';
import EmptyBox from '../../components/EmptyBox';

export default function PageChallengeAccounts() {
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const isInitialMount = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  // 
  const [isCredentialsModal, setIsCredentialsModal] = useState(false);
  const [challengeRequests, setChallengeRequests] = useState(false);
  const [realAccountRequests, setRealAccountRequests] = useState(false);
  const [challengeRecords, setChallengeRecords] = useState(false);
  const [stepValue, setStepValue] = useState('');
  const [accountStatusValue, setAccountStatusValue] = useState('');
  const [typeValue, setTypeValue] = useState('');
  const [masterPassword, setMasterPassword] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);


  const handleCredentialsModalShow = (account) => {
    setAccountNumber(account.account_number)
    setMasterPassword(account.master_password)
    setIsCredentialsModal(true)
  }

  const handleCredentialsModalClose = () => {
    setIsCredentialsModal(false)
  }


  // --- Payout type Tabbing Js Start
  const [isAccountTab, setIsAccountTab] = useState('acActive');

  const handleAccountTabClick = (accountId) => {
    setIsAccountTab(accountId);
  };
  // --- Payout type tabbing Js end
  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;

      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {

            const requests = await getChallengeRequests();
            const accountRequests = await getRealAccountRequests();
            const records = await getChallengeRecords();
            setDataLoaded(true)
            // console.log(records);
            // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
          } catch (error) {
            console.error('Failed to fetch wallet group data:', error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
  }, [token]);
  const getChallengeRequests = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL + 'get-challenge-requests', {
        user_id: state.userData.id,
        token: token,
        step: stepValue,
        account_status: accountStatusValue,
        type: typeValue,
        nopaginate: 1
      });
      if (response.data.success == '0') {
        // console.log(response.data.data);
        if (response.data.data.length > 0) {
          setChallengeRequests(response.data.data);
        } else {
          setChallengeRequests(null);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const getRealAccountRequests = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL + 'get-real-account-requests', {
        user_id: state.userData.id,
        token: token,
        nopaginate: 1
      });
      if (response.data.success == '0') {
        // console.log(response.data.data);
        if (response.data.data.length > 0) {
          console.log('realaccountrequest' + response.data.data);
          setRealAccountRequests(response.data.data);
        } else {
          setRealAccountRequests(null);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const getChallengeRecords = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL + 'get-challenges', {
        user_id: state.userData.id,
        status: 'active',
        token: token,
        step: stepValue,
        account_status: accountStatusValue,
        type: typeValue,
        nopaginate: 1
      });
      if (response.data.success == '0') {
        if (response.data.data.length > 0) {
          //console.log(response.data.data);
          setChallengeRecords(response.data.data);
        } else {
          setChallengeRecords(null);
        }

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const formatStatus = (status) => {
    if (!status) return ''; // Handle case where status might be undefined or null
    if(status == 'passed'){
      status = 'Profit target achieved';
    }
    const formattedStatus = status.replace(/_/g, ' '); // Replace underscores with spaces
    return formattedStatus.charAt(0).toUpperCase() + formattedStatus.slice(1); // Capitalize first letter
  };
  const copyClick = (value) => {
    setRequestSubmitSuccsess(false);
    setRequestSubmitError(false);

    navigator.clipboard.writeText(value)
      .then(() => {
        setRequestSubmitSuccsess(true);
        setRequestSubmitSuccessMsg('Text copied to clipboard');
        // console.log('Text copied to clipboard');
      })
      .catch((error) => {
        setRequestSubmitError(true)
        setRequestSubmitErrorMsg('Error copying text')
        // console.error('Error copying text: ', error);
      });
  };
  return (
    <>
      {!dataLoaded && <Loaders />}
      <Breadcrumb className="" breadcrumbIcon="EwalletsSvg" breadcrumbHeading="Accounts" />
      <div className="container-lg cl-custome3">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="common-box">

              {/* --- Account Tabing btn start --- */}
              <div className="common-tabs-bx mb-4">
                <div className={`ctb-item ${isAccountTab === 'acActive' ? 'active' : ''}`} onClick={() => handleAccountTabClick('acActive')}>Active</div>
                <div className={`ctb-item ${isAccountTab === 'acPending' ? 'active' : ''}`} onClick={() => handleAccountTabClick('acPending')}>Pending</div>
                {/* <div className={`ctb-item ${isAccountTab === 'acRejected' ? 'active' : ''}`} onClick={() => handleAccountTabClick('acRejected')}>Rejected</div> */}
              </div>
              {/* --- Account Tabing btn end --- */}

              {/* --- Active account section start --- */}
              <div className={`row row-gap-4 ${isAccountTab === 'acActive' ? '' : 'd-none'}`} id="acActive">
                {challengeRecords && challengeRecords.map((option, index) => (
                  // const accountType = option.account_type || 'defaultType'
                  // const step = option.account_type || 'defaultStep'
                  <ChallengeCard
                    key={index}
                    col="col-lg-4 col-md-6 col-12"
                    to={"/account-detail/" + option.id}
                    className={'active'}
                    //imgSrc={(option.account_type != undefined && option.account_type.name == 'Student') ? student : (option.account_type != undefined && option.account_type.name == 'Practitioner' ? practitioner : master)}
                    imgSrc={((option.account_status == 'real') ? practitioner : student)}
                    step={option.account_type != undefined ? option.account_type.step : ''}
                    name={"MT5 " + option.account_number}
                    //type={option.account_type != undefined ? option.account_type.name : ''}
                    type={((option.account_status == 'real') ? 'Practitioner' : 'Student')}
                    status={formatStatus(option.account_status)}
                    statusClass={(option.account_status == 'not_passed') ? 'status-red2' : (option.account_status == 'refunded' ? 'status-yellow2' : (option.account_status == 'passed' ? 'status-green2' : (option.account_status == 'disabled' ? 'status-yellow2' : 'status-yellow2')))}
                    trades={option.no_of_trades}
                    days={option.days_traded}
                    CredentialsClick={() => handleCredentialsModalShow(option)}
                  />
                ))}
                {(challengeRecords === null && challengeRequests === null) &&
                  <EmptyBox className="empty-div-bx" eh="Accounts not found" esh="" />
                }
              </div>
              {/* --- Active account section end --- */}


              {/* --- Pending account section start --- */}
              <div className={`row row-gap-4 ${isAccountTab === 'acPending' ? '' : 'd-none'}`} id="acPending">
                {challengeRequests && challengeRequests.map((option, index) => (
                  <ChallengeRequestCard key={index} col="col-lg-4 col-md-6 col-12" name={option.account_type.name} step={option.account_type.step} limit={option.account_size.limit} status={option.account_status} />
                ))}

                {realAccountRequests && realAccountRequests.map((option, index) => (
                  <ChallengeRequestCard key={index} col="col-lg-4 col-md-6 col-12" name={option.user_wallet.account_number} step={option.user_wallet.account_type.step} limit={option.user_wallet.account_size.limit} isRealAccount={true} status={option.account_status} />
                ))}
                {(realAccountRequests === null && challengeRequests === null) &&
                  <EmptyBox className="empty-div-bx" eh="Requests not found" esh="" />
                }
              </div>
              {/* --- Pending account section end --- */}

            </div>
          </div>
        </div>
      </div>

      {/* --- Credentials Modal --- */}
      <Modal className="zoom custom-content" centered show={isCredentialsModal} onHide={handleCredentialsModalClose}>
        <div className="custom-modal-header">
          <div className="cmh-lable">Account Credentials</div>
          <div className="cmh-sub-lable">{'#' + accountNumber}</div>

          <CloseIcon onClick={handleCredentialsModalClose} />
        </div>

        <div className="custom-modal-body">
          <div className="challenge-credentials-bx">

            {/* <div className= {"credential-item "+ email != '' ? 'd-none':''}>
                  <div className="credential-label">Email</div>
                  <div className="credential-data">{email}<span className="credential-icon" onClick={() => copyClick(email)}><Icons.CopySvg /></span></div>
              </div> */}
            <div className="credential-item">
              <div className="credential-label">Username</div>
              <div className="credential-data">{accountNumber}<span className="credential-icon" onClick={() => copyClick(accountNumber)}><Icons.CopySvg /></span></div>
            </div>
            <div className="credential-item">
              <div className="credential-label">Password</div>
              <div className="credential-data">{masterPassword}<span className="credential-icon" onClick={() => copyClick(masterPassword)}><Icons.CopySvg /></span></div>
            </div>
            <div className="credential-item">
              <div className="credential-label">Server</div>
              <div className="credential-data">Winprofx-Live<span className="credential-icon" onClick={() => copyClick("Winprofx-Live")}><Icons.CopySvg /></span></div>
            </div>
            <div className="credential-item">
              <div className="credential-label">Platform</div>
              <div className="credential-data">Metatrader 5<span className="credential-icon" onClick={() => copyClick("Metatrader 5")}><Icons.CopySvg /></span></div>
            </div>
          </div>
          <div className="mt-3 mb-4">
            <a download={true} href={mt5exe} className="common-btn cb-eg3-fill cwf-am-hover mx-auto"><span>Open META TRADER 5</span></a>
          </div>
        </div>
      </Modal>
      <FlashMessage type="success" isVisible={requestSubmitSuccess} message={requestSubmitSuccessMsg} />
      <FlashMessage type="error" isVisible={requestSubmitError} message={requestSubmitErrorMsg} />
    </>
  );
}
